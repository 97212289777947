<template>
  <div>
    <div class="grid place-items-center w-full">
      <div class="">
        <child />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginLayout",

  methods: {},
};
</script> 

<style scoped>
.logo {
  height: 120px;
}

.onDrag {
  -webkit-app-region: no-drag;
}

button {
  -webkit-app-region: no-drag;
}
</style>

