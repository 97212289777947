<template>
  <div class="container-scroller">
    <div class="container-fluid page-body-wrapper full-page-wrapper">
      <div class="content-wrapper d-flex align-items-center auth px-0">
        <child />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BasicLayout",
};
</script>
