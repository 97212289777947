<template>
  <div id="hide-to-print" class="
      fixed
      w-full
      flex
      items-center
      justify-between
      h-14
  
      text-red
      z-10
      __hide__to_print
    ">

    <div class="
        flex
        w-full
        justify-between
        items-center
        h-14
        border-b
        bg-white
        dark:bg-gray-800
        header-right
      ">
      <div :class="`flex items-center ${isSidebarOpen && 'pl-64'}`">
        <button class="mx-3" @click="toggleSidebar">
          <i class="bx bx-menu text-2xl"></i>
        </button>
        <div>
          <router-link v-if="$route.name != 'tellerportal'" to="/tellerportal"
            class="bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded text-white hidden sm:block">
            Teller Portal
          </router-link>


          <button v-else @click="$router.go(-1)"
            class="bg-red-500 hover:bg-red-600 px-4 py-2 rounded text-white hidden sm:inline-block">
            Close Teller Portal
          </button>
        </div>
      </div>
      <ul class="flex items-center">
        <li class="mx-4">
          <router-link to="/deposit/agent/new"
            class="bg-green-600 hover:bg-green-400 px-4 py-2  rounded text-white hidden sm:inline-block">
            <i class="bx bxs-coin-stack"></i>
            Deposit
          </router-link>
        </li>
        <li>
          <b>{{ user.name }}</b>
        </li>
        <li>
          <div class="block w-px h-6 mx-3 bg-gray-400 dark:bg-gray-700"></div>
        </li>
        <li>
          <button @click="logout" class="flex items-center mr-4 hover:text-gray-500 text-black">
            <span class="inline-flex mr-1">
              <svg class="w-5 h-5" fill="none" stroke="#333" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"></path>
              </svg>
            </span>
            Logout
          </button>
        </li>
        <li>
          <div class="block w-px mr-3 h-6 bg-gray-400 dark:bg-gray-700"></div>
        </li>
        <li class="pr-4">
          <h1 class="font-bold">{{ user.data.name }}</h1>
        </li>
      </ul>
    </div>
  </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";

export default {

  // Access props
  props: {
    isSidebarOpen: {
      required: true,

    }
  },

  data: () => ({
    appName: "Chapman Xpress",
  }),

  computed: mapGetters({
    user: "auth/user",
  }),

  methods: {
    ...mapActions('ui', ['toggleSidebar']),

    setFullScreen: function () {
      window.resizeTo(screen.width, screen.height);
      window.statusbar;
      console.log(window.resizeTo);
    },

    minimize() { },

    async logout() {
      // Log out the user.
      await this.$store.dispatch("auth/logout");

      // Redirect to login.
      this.$router.push({ name: "login" });
    },
  },
};
</script>

<style scoped>
@media print {
  body * {
    visibility: hidden;
  }

  #hide-to-print #hide-to-print * {
    visibility: hidden;
  }

  .__hide__to_print {
    visibility: hidden;
  }
}
</style>
