// store/modules/ui.js


import * as types from '../mutation-types';


// State object
export const state = {
    isSidebarOpen: true,
    isMobile: false,
};

// Getters
export const getters = {
    isSidebarOpen: state => state.isSidebarOpen,
    isMobile: state => state.isMobile,
};

// Mutations
export const mutations = {
    [types.TOGGLE_SIDEBAR](state) {
        state.isSidebarOpen = !state.isSidebarOpen;
    },

    [types.ENABLE_MOBILE](state) {
        state.isMobile = true;
    },
};

// Actions
export const actions = {
    toggleSidebar({ commit }) {
        commit(types.TOGGLE_SIDEBAR);
    },

    enableMobile({ commit }) {
        commit(types.ENABLE_MOBILE);
    },
};
