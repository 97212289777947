<template>
  <div>
    <div v-if="data.meta" class="flex justify-between items-center py-2 px-4">
      <div class="text-gray-600">
        Showing {{ data.meta.current_page }} to {{ data.meta.last_page }} of
        {{ data.meta.total }} results
      </div>

      <ul v-if="data.meta.last_page > 1" class="flex items-center my-2">
        <li
          :class="[data.meta.current_page == 1 ? 'disabled' : '']"
          class="
            h-8
            w-8
            mr-1
            flex
            justify-center
            items-center
            rounded-full
            bg-gray-200
            cursor-pointer
          "
        >
          <a
            v-if="data.meta.current_page != 1"
            :href="'?page=' + data.meta.current_page"
            aria-label="Previous"
            @click.prevent="pageClicked(data.meta.current_page - 1)"
          >
            <span aria-hidden="true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-left w-4 h-4"
              >
                <polyline points="15 18 9 12 15 6"></polyline>
              </svg>
            </span>
          </a>
          <a v-else>
            <span aria-hidden="true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-left w-4 h-4"
              >
                <polyline points="15 18 9 12 15 6"></polyline></svg
            ></span>
          </a>
        </li>
        <li
          v-for="pageNo in range(paginateLoop, numberOfPage)"
          :key="pageNo"
          :class="[
            data.meta.current_page == pageNo ? 'bg-blue-600 text-white' : '',
          ]"
          class="
            w-8
            h-8
            font-medium
            rounded-full
            bg-gray-200
            md:flex
            justify-center
            items-center
            hidden
            cursor-pointer
            leading-5
            transition
            duration-150
            ease-in
            rounded-full
          "
        >
          <a :href="'?page=' + pageNo" @click.prevent="pageClicked(pageNo)">{{
            pageNo
          }}</a>
        </li>
        <li
          :class="[
            data.meta.current_page == data.meta.last_page ? 'disabled' : '',
          ]"
          class="
            h-8
            w-8
            ml-1
            flex
            justify-center
            items-center
            rounded-full
            bg-gray-200
            cursor-pointer
          "
        >
          <a
            v-if="data.meta.current_page != data.meta.last_page"
            :href="'?page=' + data.meta.current_page"
            aria-label="Next"
            @click.prevent="pageClicked(data.meta.current_page + 1)"
          >
            <span aria-hidden="true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-right w-4 h-4"
              >
                <polyline points="9 18 15 12 9 6"></polyline></svg
            ></span>
          </a>
          <a v-else>
            <span aria-hidden="true">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="100%"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-chevron-right w-4 h-4"
              >
                <polyline points="9 18 15 12 9 6"></polyline></svg
            ></span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      required: true,
    },
    midSize: {
      required: false,
      type: Number,
      default: 5,
    },
  },

  data() {
    return {};
  },

  computed: {
    paginateLoop() {
      let results = this.data;
      if (results.meta.last_page > this.midSize) {
        if (
          results.meta.last_page - (this.midSize - 1) / 2 <=
          results.meta.current_page
        ) {
          return results.meta.last_page - (this.midSize - 1);
        }

        if (results.meta.current_page > (this.midSize - 1) / 2 + 1) {
          return results.meta.current_page - (this.midSize - 1) / 2;
        }
      }

      return 1;
    },

    numberOfPage() {
      let results = this.data;
      if (results.meta.last_page < this.midSize) {
        return results.meta.last_page;
      } else {
        return this.midSize;
      }
    },
  },

  methods: {
    range(start, count) {
      return Array.apply(0, Array(count)).map(function (element, index) {
        return index + start;
      });
    },
    pageClicked(pageNo) {
      this.$emit("clicked", pageNo);
    },
  },
};
</script>
