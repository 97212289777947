<template>
  <div
    class="flex justify-between items-center w-full px-6 py-6 fixed bg-gray-50"
  >
    <div class="flex items-center">
      <i class="bx bx-search _search_icon"></i>

      <div>
        <input
          class="search_input focus:outline-none"
          type="search"
          placeholder="Search "
        />
      </div>
    </div>

    <div class="flex items-center">
      <clock />
      <!-- Profile Dropdown -->
      <div
        class="
          bg-gray-300
          w-11
          h-11
          rounded-full
          flex
          justify-center
          items-center
        "
      >
        AJ
      </div>
    </div>
  </div>
</template>

<script>
import Clock from "../components/Clock.vue";
export default {
  components: {
    Clock,
  },
};
</script>