<template>
  <div id="app" class="bg-white -webkit-app-region: drag">
    <transition name="page" mode="out-in">
      <component :is="layout" v-if="layout" />
    </transition>
  </div>
</template>

<script>
// Load layout components dynamically.
const requireContext = require.context("./layouts", false, /.*\.vue$/);

const layouts = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.vue$)/g, ""), requireContext(file)])
  .reduce((components, [name, component]) => {
    components[name] = component.default || component;
    return components;
  }, {});

export default {
  el: "#app",

  components: {},

  data: () => ({
    layout: null,
    defaultLayout: "default",
  }),

  created() {},

  metaInfo() {

    return {
      title: "Chapman Xpress",
      titleTemplate: `%s · Chapman Xpress`,
    };
  },

  // mounted() {
  //   // console.log("Firebase cloud messaging object", this.$messaging);

  //   // this.$messaging.getToken({ vapidKey: 'BDgELPO-pPR6IXA_PEYEi7HajWORTta4LAL46Af4NMIdmsqNLgm-vwYapw5nKZQZc5WTQMwhnBwxITCnko2NDPM' })
  //   //   .then((currentToken) => {
  //   //     if (currentToken) {
  //   //       console.log('client token', currentToken)
  //   //     } else {
  //   //       console.log('No registration token available. Request permission to generate one.');
  //   //     }
  //   //   }).catch((err) => {
  //   //     console.log('An error occurred while retrieving token. ', err);
  //   //   })
  // },

  methods: {
    /**
     * Set the application layout.
     *
     * @param {String} layout
     */
    setLayout(layout) {
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout;
      }

      this.layout = layouts[layout];
    },
  },
};
</script>

<style>
.wide-lg {
  max-width: 1244px !important;
  margin: 0 auto;
}
body {
  background: #fff;
}
._navicon {
  font-size: 28px;
  color: #6e749a;
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://example.com/MaterialIcons-Regular.eot);
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url(https://example.com/MaterialIcons-Regular.woff2) format("woff2"),
    url(https://example.com/MaterialIcons-Regular.woff) format("woff"),
    url(https://example.com/MaterialIcons-Regular.ttf) format("truetype");
}

.search_input {
  background: transparent;
  padding: 5px 10px;
  width: 300px;
}

._search_icon {
  font-size: 24px;
  color: #c6c8d4;
}

._active {
  color: #2558c7 !important;
}

#app {
}

.logo {
  padding: 3px;
}
</style>
