<template>
  <div v-if="show" class="flex justify-center items-center h-80">
    <div class="flex-col justify-center items-center">
      <div
        style="margin: 0 auto"
        class="
          loader
          ease-linear
          rounded-full
          border-8 border-t-8 border-gray-100
          h-14
          w-14
        "
      ></div>

      <div class="mt-4 text-gray-600">
        <span>{{ content }}, please wait...</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["show", "content"],
};
</script>

<style scoped>
.loader {
  border-top-color: #0063a5;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>