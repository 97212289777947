<template>
  <div class="flex w-full">
    <!-- Navigation -->
    <navigation />
    <!-- End Navigation -->

    <div class="w-full flex flex-col h-full justify-between ml-24">
      <!-- Top Bar -->
      <top-bar />
      <!-- End Top Bar -->

      <!-- Content Start -->
      <div class="md:px-40 pt-28 sm:my-20 reletive">
        <div class="w-full bg-white shadow rounded-lg mb-8 px-2">
          <ul class="flex items-center">
            <li class="">
              <router-link
                :class="{ isactive: $route.name == 'staffs' }"
                class="py-3 text-center px-20"
                to="/staffs"
                >Staffs</router-link
              >
            </li>
            <li class="">
              <router-link
                :class="{ isactive: $route.name == 'branches' }"
                class="py-3 text-center px-20"
                to="/branches"
                >Branches</router-link
              >
            </li>
            <li class="py-3 text-center px-20">
              <router-link
                :class="{ isactive: $route.name == 'accounts' }"
                class="py-3 text-center px-20"
                to="/accounts"
                >Accounts</router-link
              >
            </li>
          </ul>
        </div>

        <child />
      </div>
      <!-- Content End -->

      <footer class="border-t px-9 py-4 text-sm text-gray-400">
        <p>Build with love by Innovative Technology Group (ITG)</p>
      </footer>
    </div>
  </div>
</template>

<script>
import Navigation from "../components/Navigation";
import TopBar from "../components/TopBar";
export default {
  name: "MainLayout",

  components: { Navigation, TopBar },
};
</script>


<style scoped>
._maincontent {
  margin: 0 auto;
}

.isactive {
  border-bottom: 2px solid rgb(10, 168, 70);
}
</style>