<template>
  <div>
    <div class="
        flex flex-col flex-auto flex-shrink-0
        antialiased
        bg-white
        h-screen
        dark:bg-gray-700
        text-black
        dark:text-white
      ">
      <!-- Header -->
      <Header :isSidebarOpen="isSidebarOpen" />
      <!-- ./Header -->

      <!-- Sidebar -->
      <div id="app-container" class="flex h-screen flex-shrink-0 antialiased bg-white text-gray-800">
        <transition name="slide">
          <div v-if="isSidebarOpen" class="fixed inset-0 bg-gray-600 bg-opacity-95 z-40 lg:hidden">
            <button @click="toggleSidebar(false)">
              <i class="bx bx-x text-3xl text-white absolute top-5 right-5"></i>
            </button>
          </div>
        </transition>
        <transition name="slide">
          <div v-if="isSidebarOpen"
            class="flex flex-col fixed top-0 left-0 h-full transform z-50 w-64 bg-white border-r lg:transform-none">

            <div class="flex items-center justify-center h-14 border-b">
              <router-link to="/dashboard">
                <img class="h-10 mb-2 mt-2 overflow-hidden" src="../assets/mainlogo.jpg" />
              </router-link>
            </div>
            <div class="flex-grow overflow-auto">
              <ul class="flex flex-col py-4 space-y-1">
                <li class="px-5">

                </li>
                <li v-if="this.checkPermissionExist(user.data, 'dashboard-read')">
                  <router-link :class="{
        'bg-gray-50 border-indigo-500': $route.fullPath == '/dashboard',
      }" to="/dashboard" class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-600
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              ">
                    <span class="inline-flex justify-center items-center ml-4">
                      <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6">
                        </path>
                      </svg>
                    </span>
                    <span class="ml-2 text-sm tracking-wide truncate">Dashboard</span>
                  </router-link>
                </li>

                <li v-if="this.checkPermissionExist(user.data, 'deposit-read')">
                  <router-link to="/deposits" :class="{
        'bg-gray-50 border-indigo-500': $route.fullPath == '/deposits',
      }" class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-600
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              ">
                    <span class="inline-flex justify-center items-center ml-4">
                      <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4">
                        </path>
                      </svg>
                    </span>
                    <span class="ml-2 text-sm tracking-wide truncate">Deposits</span>
                  </router-link>
                </li>

                <li v-if="this.checkPermissionExist(user.data, 'transaction-read')">
                  <router-link :class="{
        'bg-gray-50 border-indigo-500':
          $route.fullPath == '/new_transactions',
      }" to="/new_transactions" class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-800
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              ">
                    <span class="inline-flex justify-center items-center ml-4">
                      <i class="bx bx-transfer text-xl"></i>
                    </span>
                    <span class="ml-2 text-sm tracking-wide truncate">Transactions</span>
                    <!-- <span class="bg-green-300 w-2 h-2 rounded-full new-menu"></span> -->
                  </router-link>
                </li>



                <li v-else>
                  <router-link :class="{
        'bg-gray-50 border-indigo-500':
          $route.fullPath == '/branch_transactions',
      }" to="/branch_transactions" class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-800
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              ">
                    <span class="inline-flex justify-center items-center ml-4">
                      <i class="bx bx-transfer text-xl"></i>
                    </span>
                    <span class="ml-2 text-sm tracking-wide truncate">Transactions</span>
                  </router-link>
                </li>

                <li v-if="this.checkPermissionExist(user.data, 'floats-read')">
                  <router-link :class="{
        'bg-gray-50 border-indigo-500':
          $route.fullPath == '/manage_floats',
      }" to="/manage_floats" class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-800
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              ">
                    <span class="inline-flex justify-center items-center ml-4">
                      <i class="bx bx-bookmark-plus text-xl"></i>
                    </span>
                    <span class="ml-2 text-sm tracking-wide truncate">Manage Float</span>
                  </router-link>
                </li>

                <li v-if="this.checkPermissionExist(user.data, 'floats-read')">
                  <router-link :class="{
        'bg-gray-50 border-indigo-500':
          $route.fullPath == '/commission_center',
      }" to="/commission_center" class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-800
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              ">
                    <span class="inline-flex justify-center items-center ml-4">
                      <i class="bx bx-bookmark-plus text-xl"></i>
                    </span>
                    <span class="ml-2 text-sm tracking-wide truncate">Commision Center</span>
                  </router-link>
                </li>
                <li v-if="this.checkPermissionExist(user.data, 'worksheets-read')">
                  <router-link :class="{
        'bg-gray-50 border-indigo-500':
          $route.fullPath == '/worksheets',
      }" to="/worksheets" class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-800
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              ">
                    <span class="inline-flex justify-center items-center ml-4">
                      <i class="bx bx-briefcase-alt text-xl"></i>
                    </span>
                    <span class="ml-2 text-sm tracking-wide truncate">Worksheets</span>
                  </router-link>
                </li>
                <li class="px-5">
                  <div class="flex flex-row items-center h-8">
                    <div class="text-sm font-light tracking-wide text-gray-500">
                      <!-- Tasks -->
                    </div>
                  </div>
                </li>
                <li v-if="this.checkPermissionExist(user.data, 'cashcollection-read')">
                  <router-link to="/cashcollection" class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-800
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              ">
                    <span class="inline-flex justify-center items-center ml-4">
                      <i class="bx bx-collection text-xl"></i>
                    </span>
                    <span class="ml-2 text-sm tracking-wide truncate">Cash Collection</span>
                  </router-link>
                </li>

                <li v-if="this.checkPermissionExist(user.data, 'cashcollection-branch-read')
        ">
                  <router-link to="/branch_cashcollection" class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-800
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              ">
                    <span class="inline-flex justify-center items-center ml-4">
                      <i class="bx bx-collection text-xl"></i>
                    </span>
                    <span class="ml-2 text-sm tracking-wide truncate">Branch Cash Collection</span>
                  </router-link>
                </li>
                <li v-if="this.checkPermissionExist(user.data, 'reconciliations-read')">
                  <router-link to="/reconciliations" class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-800
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              ">
                    <span class="inline-flex justify-center items-center ml-4">
                      <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01">
                        </path>
                      </svg>
                    </span>
                    <span class="ml-2 text-sm tracking-wide truncate">Reconciliations</span>
                  </router-link>
                </li>
                <li v-if="this.checkPermissionExist(user.data, 'agents-read')">
                  <router-link :class="{
        'bg-gray-50 border-indigo-500': $route.fullPath == '/agents',
      }" to="/agents" class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-800
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              ">
                    <span class="inline-flex justify-center items-center ml-4">
                      <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z">
                        </path>
                      </svg>
                    </span>
                    <span class="ml-2 text-sm tracking-wide truncate">Agents</span>
                  </router-link>
                </li>

                <li v-if="this.checkPermissionExist(user.data, 'agents-read')">
                  <router-link :class="{
        'bg-gray-50 border-indigo-500':
          $route.fullPath == '/superagents',
      }" to="/superagents" class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-800
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              ">
                    <span class="inline-flex justify-center items-center ml-4">
                      <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z">
                        </path>
                      </svg>
                    </span>
                    <span class="ml-2 text-sm tracking-wide truncate">Super Agents</span>
                  </router-link>
                </li>

                <li>
                  <router-link :class="{
        'bg-gray-50 border-indigo-500':
          $route.fullPath == '/agent_transactions',
      }" to="/agent_transactions" class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-800
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              ">
                    <span class="inline-flex justify-center items-center ml-4">
                      <i class="bx bx-transfer text-xl"></i>
                    </span>
                    <span class="ml-2 text-sm tracking-wide truncate">Agent Transactions</span>
                  </router-link>
                </li>

                <li class="px-5">
                  <div class="flex flex-row items-center h-8">
                    <div class="text-sm font-light tracking-wide text-gray-500"></div>
                  </div>
                </li>
                <li v-if="this.checkPermissionExist(user.data, 'staffs-read')">
                  <router-link :class="{
        'bg-gray-50 border-indigo-500': $route.fullPath == '/staffs',
      }" to="/staffs" class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-800
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              ">
                    <span class="inline-flex justify-center items-center ml-4">
                      <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                      </svg>
                    </span>
                    <span class="ml-2 text-sm tracking-wide truncate">Staffs</span>
                  </router-link>
                </li>

                <li v-if="this.checkPermissionExist(user.data, 'branchs-read')">
                  <router-link :class="{
        'bg-gray-50 border-indigo-500': $route.fullPath == '/branches',
      }" to="/branches" class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-800
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              ">
                    <span class="inline-flex justify-center items-center ml-4">
                      <!-- <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);transform: ;msFilter:;"><path d="M19 2H9c-1.103 0-2 .897-2 2v5.586l-4.707 4.707A1 1 0 0 0 3 16v5a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4c0-1.103-.897-2-2-2zm-8 18H5v-5.586l3-3 3 3V20zm8 0h-6v-4a.999.999 0 0 0 .707-1.707L9 9.586V4h10v16z"></path><path d="M11 6h2v2h-2zm4 0h2v2h-2zm0 4.031h2V12h-2zM15 14h2v2h-2zm-8 1h2v2H7z"></path></svg> -->
                      <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" style="" viewBox="0 0 24 24">
                        <path
                          d="M19 2H9c-1.103 0-2 .897-2 2v5.586l-4.707 4.707A1 1 0 0 0 3 16v5a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V4c0-1.103-.897-2-2-2zm-8 18H5v-5.586l3-3 3 3V20zm8 0h-6v-4a.999.999 0 0 0 .707-1.707L9 9.586V4h10v16z">
                        </path>
                        <path d="M11 6h2v2h-2zm4 0h2v2h-2zm0 4.031h2V12h-2zM15 14h2v2h-2zm-8 1h2v2H7z"></path>
                      </svg>
                    </span>
                    <span class="ml-2 text-sm tracking-wide truncate">Branches</span>
                  </router-link>
                </li>

                <li v-if="user.data.staff">
                  <router-link to="/myprofile" class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-800
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              ">
                    <span class="inline-flex justify-center items-center ml-4">
                      <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"></path>
                      </svg>
                    </span>
                    <span class="ml-2 text-sm tracking-wide truncate">My Profile</span>
                  </router-link>
                </li>

                <li v-if="this.checkPermissionExist(user.data, 'report-read')">
                  <router-link to="/reports" class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-800
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              ">
                    <span class="inline-flex justify-center items-center ml-4">
                      <i class="bx bxs-report text-xl"></i>
                    </span>
                    <span class="ml-2 text-sm tracking-wide truncate">Reports</span>
                  </router-link>
                </li>

                <li>
                  <router-link to="/settings/profile" class="
                relative
                flex flex-row
                items-center
                h-11
                focus:outline-none
                hover:bg-gray-50
                text-gray-600
                hover:text-gray-800
                border-l-4 border-transparent
                hover:border-indigo-500
                pr-6
              ">
                    <span class="inline-flex justify-center items-center ml-4">
                      <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z">
                        </path>
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                      </svg>
                    </span>
                    <span class="ml-2 text-sm tracking-wide truncate">Settings</span>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </transition>

        <div :class="`flex-grow pt-24 px-5 ${isSidebarOpen && 'ml-64'}`">
          <!-- Main content goes here -->
          <child />
        </div>

      </div>

    </div>
  </div>
  <!-- ================ END ===================== -->
</template>

<script>

import Header from "../components/Header";
// import SideBar from "../components/SideBar";
import { mapGetters, mapActions } from "vuex";
import checkPermissionExist from "@/mixins/checkPermissionExist";

export default {
  name: "MainLayout",
  mixins: [checkPermissionExist],

  components: { Header },

  mounted() {
    // Check if the screen width is less than or equal to 1024px (you can adjust this value accordingly)
    if (window.innerWidth < 1019) {
      // Set isSidebarOpen to false if the screen size is mobile
      this.toggleSidebar(false);
      this.enableMobile(true);
    } else {
      // Set isSidebarOpen to true if the screen size is not mobile
      // this.toggleSidebar(true);
      this.enableMobile(false);
    }
  },

  // Watch for screen resize
  // created() {
  //   window.addEventListener("resize", this.handleResize);
  // },

  // beforeDestroy() {
  //   window.removeEventListener("resize", this.handleResize);
  // },

  methods: {
    ...mapActions({
      toggleSidebar: "ui/toggleSidebar",
      enableMobile: "ui/enableMobile",
    }),

    // handleResize() {
    //   // Check if the screen width is less than or equal to 1024px (you can adjust this value accordingly)
    //   if (window.innerWidth < 1019) {
    //     // Set isSidebarOpen to false if the screen size is mobile
    //     this.toggleSidebar(false);
    //     this.enableMobile(true);
    //   } else {
    //     // Set isSidebarOpen to true if the screen size is not mobile
    //     this.toggleSidebar(false);
    //     this.enableMobile(false);
    //   }
    // },
  },



  computed: {
    ...mapGetters({
      user: "auth/user",
      // Add the getter for isSidebarOpen from the ui module
      isSidebarOpen: "ui/isSidebarOpen",
    }),
  },



  // methods: {
  //   ...mapActions({
  //     // Add the toggleSidebar action from the ui module
  //     toggleSidebar: "ui/toggleSidebar",
  //   }),
  // },
};
</script>


<style>
/* Compiled dark classes from Tailwind */
.dark .dark\:divide-gray-700> :not([hidden])~ :not([hidden]) {
  border-color: rgba(55, 65, 81);
}

.dark .dark\:bg-gray-50 {
  background-color: rgba(249, 250, 251);
}

.dark .dark\:bg-gray-100 {
  background-color: rgba(243, 244, 246);
}

.dark .dark\:bg-gray-600 {
  background-color: rgba(75, 85, 99);
}

.dark .dark\:bg-gray-700 {
  background-color: rgba(55, 65, 81);
}

.dark .dark\:bg-gray-800 {
  background-color: rgba(31, 41, 55);
}

.dark .dark\:bg-gray-900 {
  background-color: rgba(17, 24, 39);
}

.dark .dark\:bg-red-700 {
  background-color: rgba(185, 28, 28);
}

.dark .dark\:bg-green-700 {
  background-color: rgba(4, 120, 87);
}

.dark .dark\:hover\:bg-gray-200:hover {
  background-color: rgba(229, 231, 235);
}

.dark .dark\:hover\:bg-gray-600:hover {
  background-color: rgba(75, 85, 99);
}

.dark .dark\:hover\:bg-gray-700:hover {
  background-color: rgba(55, 65, 81);
}

.dark .dark\:hover\:bg-gray-900:hover {
  background-color: rgba(17, 24, 39);
}

.dark .dark\:border-gray-100 {
  border-color: rgba(243, 244, 246);
}

.dark .dark\:border-gray-400 {
  border-color: rgba(156, 163, 175);
}

.dark .dark\:border-gray-500 {
  border-color: rgba(107, 114, 128);
}

.dark .dark\:border-gray-600 {
  border-color: rgba(75, 85, 99);
}

.dark .dark\:border-gray-700 {
  border-color: rgba(55, 65, 81);
}

.dark .dark\:border-gray-900 {
  border-color: rgba(17, 24, 39);
}

.dark .dark\:hover\:border-gray-800:hover {
  border-color: rgba(31, 41, 55);
}

.dark .dark\:text-white {
  color: rgba(255, 255, 255);
}

.dark .dark\:text-gray-50 {
  color: rgba(249, 250, 251);
}

.dark .dark\:text-gray-100 {
  color: rgba(243, 244, 246);
}

.dark .dark\:text-gray-200 {
  color: rgba(229, 231, 235);
}

.dark .dark\:text-gray-400 {
  color: rgba(156, 163, 175);
}

.dark .dark\:text-gray-500 {
  color: rgba(107, 114, 128);
}

.dark .dark\:text-gray-700 {
  color: rgba(55, 65, 81);
}

.dark .dark\:text-gray-800 {
  color: rgba(31, 41, 55);
}

.dark .dark\:text-red-100 {
  color: rgba(254, 226, 226);
}

.dark .dark\:text-green-100 {
  color: rgba(209, 250, 229);
}

.dark .dark\:text-blue-400 {
  color: rgba(96, 165, 250);
}

.dark .group:hover .dark\:group-hover\:text-gray-500 {
  color: rgba(107, 114, 128);
}

.dark .group:focus .dark\:group-focus\:text-gray-700 {
  color: rgba(55, 65, 81);
}

.dark .dark\:hover\:text-gray-100:hover {
  color: rgba(243, 244, 246);
}

.dark .dark\:hover\:text-blue-500:hover {
  color: rgba(59, 130, 246);
}

/* Custom style */
.header-right {
  width: calc(100% - 3.5rem);
}

.sidebar:hover {
  width: 16rem;
}

.slide-leave-active {
  transition: transform 0.3s ease;
}

@media only screen and (min-width: 768px) {
  .header-right {
    width: calc(100% - 16rem);
  }
}

._maincontent {
  margin: 0 auto;
}

@media print {
  body * {
    visibility: hidden;
  }

  #app-container,
  #app-container * {
    visibility: visible;
  }

  .print__hide {
    display: none;
  }
}
</style>
