<template>
  <div class="mx-9">
    <div class="clock-border flex justify-center items-center">
      <i class="bx bx-time-five _clock_icon"></i>
      <div class="clock-inner" :class="color">
        <div class="hour">{{ hours }}</div>
        <div class="dots">:</div>
        <div class="min">{{ minutes }}</div>
        <div class="dots">:</div>
        <div class="secs">{{ seconds }}</div>
        <div class="mode"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DigitalClock",
  props: {
    color: {
      type: String,
      default: "red",
    },
  },
  data() {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  },
  methods: {
    setTime() {
      setInterval(() => {
        const date = new Date();
        this.hours = date.getHours();
        this.minutes = this.checkSingleDigit(date.getMinutes());
        this.seconds = this.checkSingleDigit(date.getSeconds());
      }, 1000);
    },
    checkSingleDigit(digit) {
      return ("0" + digit).slice(-2);
    },
  },
  mounted() {
    this.setTime();
  },
};
</script>

<style>
.container {
}
.hour,
.min,
.secs {
  font-size: 40px;
}

.clock-border {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 700px;
  height: 200px; */
}
.clock-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 600px;
  height: 150px; */
  /* background: black; */
  border-radius: 20px;
  color: rgb(163, 163, 163);
}
._clock_icon {
  font-size: 40px;
  color: rgb(163, 163, 163);
}
.dots {
  font-size: 30px;
}
.red {
  color: rgb(189, 189, 189);
}
.yellow {
  color: yellow;
}
.green {
  color: green;
}
</style>
