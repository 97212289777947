function view(path) {
    return () =>
        import(/* webpackChunkName: '' */ `../views/${path}`).then(
            m => m.default || m
        );
}

export default [
    { path: '/', name: 'login', component: view('auth/Login.vue') },

    { path: '/test-print', component: view('TestPrint') },

    {
        path: '/dashboard',
        name: 'Dashboard',
        component: view('Dashboard/Index.vue'),
    },

    { path: '/agents', name: 'Agents', component: view('Agents/Index.vue') },

    {
        path: '/superagents',
        name: 'SuperAgents',
        component: view('SuperAgents/Index.vue'),
    },

    {
        path: '/superagents/add',
        name: 'SuperAgents.add',
        component: view('SuperAgents/Add.vue'),
    },

    {
        path: '/superagents/:id',
        component: view('SuperAgents/_view.vue'),
    },

    {
        path: '/agents/add',
        name: 'Agents.add',
        component: view('Agents/Add.vue'),
    },
    {
        path: '/agents/:id',
        component: view('Agents/_view.vue'),
    },
    {
        path: '/agents/:id/edit',
        component: view('Agents/_edit.vue'),
    },

    {
        path: '/transactions',
        name: 'transactions',
        component: view('Transactions/Index.vue'),
    },

    {
        path: '/new_transactions',
        name: 'newTransactions',
        component: view('Transactions/NewTransactions.vue'),
    },

    {
        path: '/transaction/:id',
        name: 'transaction',
        component: view('Transactions/_view.vue'),
    },

    // BranchTransactions

    {
        path: '/branch_transactions',
        name: 'BranchTransactions',
        component: view('Transactions/BranchTransactions.vue'),
    },
    {
        path: '/branch/:id',
        component: view('Branches/_view.vue'),
    },

    {
        path: '/agent_transactions',
        name: 'AgentTransactions',
        component: view('AgentTransactions/Index.vue'),
    },

    {
        path: '/service',
        name: 'service',
        component: view('Service/Index.vue'),
    },

    {
        path: '/staffs',
        name: 'staffs',
        component: view('Office/Staff/Index.vue'),
    },

    {
        path: '/staffs/create',
        name: 'staffs.create',
        component: view('Office/Staff/Create.vue'),
    },

    {
        path: '/myprofile',
        name: 'myprofile',
        component: view('Office/Staff/Myprofile.vue'),
    },

    {
        path: '/staffs/:id',
        component: view('Office/Staff/_view.vue'),
    },
    {
        path: '/staffs/:id/edit',
        component: view('Office/Staff/_edit.vue'),
    },

    {
        path: '/deposits',
        name: 'deposits',
        component: view('Deposit/Index.vue'),
    },

    {
        path: '/deposit/agent/new',
        name: 'deposit.agent.new',
        component: view('Deposit/AgentDeposit.vue'),
    },

    {
        path: '/commission_center',
        name: 'commissioncenter',
        component: view('Commissions/Index.vue'),
    },
    {
        path: '/commission/dissolve_commission',
        component: view('Commissions/DissolveCommission.vue'),
    },
    {
        path: '/manage_floats',
        name: 'manageFloat',
        component: view('ManageFloat/Index.vue'),
    },

    {
        path: '/manage_float/:id',
        component: view('ManageFloat/ReplenishmentView.vue'),
    },

    {
        path: '/replenishment',
        name: 'replenishment',
        component: view('ManageFloat/Replenishment.vue'),
    },

    {
        path: '/buffer_management',
        name: 'bufferManagement',
        component: view('ManageFloat/BufferManagement.vue'),
    },

    {
        path: '/worksheets',
        name: 'worksheets',
        component: view('Worksheets/Index.vue'),
    },

    {
        path: '/tellerportal',
        name: 'tellerportal',
        component: view('TellerPortal/Index.vue'),
    },

    {
        path: '/branches',
        name: 'branches',
        component: view('Branches/Index.vue'),
    },

    {
        path: '/branches/create',
        name: 'branche-create',
        component: view('Branches/Create.vue'),
    },

    {
        path: '/accounts',
        name: 'accounts',
        component: view('Office/Tabs/Accounts.vue'),
    },

    {
        path: '/cashcollection',
        name: 'cashcollection',
        component: view('Office/Collection/Index.vue'),
    },

    {
        path: '/branch_cashcollection',
        name: 'branch-cashcollection',
        component: view('Office/Collection/BranchCollection.vue'),
    },

    {
        path: '/newcollection',
        name: 'newcollection',
        component: view('Office/Collection/Create.vue'),
    },

    {
        path: '/reconciliations',
        name: 'reconciliations',
        component: view('Office/Reconciliation/Index.vue'),
    },

    {
        path: '/reconciliation/manage/:id',
        component: view('Office/Reconciliation/_manage.vue'),
    },

    {
        path: '/reports',
        name: 'reports',
        component: view('Reports/Index.vue'),
    },

    {
        path: '/report/result',
        name: 'generateReportResult',
        component: view('Reports/result.vue'),
    },

    {
        path: '/reconciliation/create',
        name: 'reconciliation-create',
        component: view('Office/Reconciliation/Create.vue'),
    },

    {
        path: '/test-print',
        component: view('Dashboard/TestPrint.vue'),
    },

    {
        path: '/settings',
        name: 'settings',
        component: view('Settings/ManageRoles.vue'),
    },

    {
        path: '/generate_worksheet',
        name: 'generateWorksheet',
        component: view('Office/GenerateWorksheet.vue'),
    },

    {
        path: '/settings/profile',
        name: 'settings.profile',
        component: view('Settings/Profile.vue'),
    },

    {
        path: '/settings/change_password',
        name: 'settings.password',
        component: view('Settings/Password.vue'),
    },

    {
        path: '/settings/managerole/:id',
        component: view('Settings/ManageRole/_view.vue'),
    },

    { path: '*', component: view('errors/404.vue') },
];
