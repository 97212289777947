import store from '../store';

export default (to, from, next) => {
    const role = store.getters['auth/user'].role;
    if (role !== 'admin') {
        next({ name: 'dashboard' });
    } else {
        next();
    }
};
