var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"\n      flex flex-col flex-auto flex-shrink-0\n      antialiased\n      bg-white\n      dark:bg-gray-700\n      text-black\n      dark:text-white\n    "},[_c('Header'),_c('SideBar'),_c('div',{staticClass:"h-full p-9 ml-14 mt-14 mb-10 md:ml-64"},[_vm._m(0),_c('div',{staticClass:"flex tabs border-b mb-9"},[_c('router-link',{staticClass:"tab tab-bordered p-4",class:{
            'border-b-2 border-blue-400': _vm.$route.path == '/settings/profile',
          },attrs:{"to":"/settings/profile"}},[_vm._v("Profile")]),(this.checkPermissionExist(_vm.user.data, 'manage-permissions'))?_c('router-link',{staticClass:"tab tab-bordered p-4",class:{
            'border-b-2 border-blue-400': _vm.$route.path == '/settings',
          },attrs:{"to":"/settings"}},[_vm._v("Manage Role")]):_vm._e(),_c('router-link',{staticClass:"tab tab-bordered p-4",class:{
            'border-b-2 border-blue-400':
              _vm.$route.path == '/settings/change_password',
          },attrs:{"to":"/settings/change_password"}},[_vm._v("Change Password")])],1),_c('child')],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex justify-between items-center mb-6"},[_c('div',[_c('h1',{staticClass:"text-4xl"},[_vm._v("Settings")])])])}]

export { render, staticRenderFns }